//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GetPopularTopics from "~/apollo/queries/getPopularTopics.gql";
import Popular_topic from "~/components/PopularTopicsList/Popular_topic.vue";

export default {
  components: { Popular_topic },
  props: {
    type: {
      type: String,
      default: "inactive",
      validator(value) {
        return ["active", "inactive"].includes(value);
      },
    },
    count: {
      type: Number,
      default: 1,
    },
    contentField: {
      type: String,
      validator(value) {
        return ["content"].includes(value);
      },
    },
    link: {
      type: Boolean,
      default: true,
    },
  },
  apollo: {
    popular_topics: {
      query: GetPopularTopics,
      variables() {
        return { first: 0 };
      },
      update({ popular_topics }) {
        return popular_topics?.edges.map((e) => e.node);
      },
    },
  },
};
