import { render, staticRenderFns } from "./ShowcaseList.vue?vue&type=template&id=2bf8492d&scoped=true&"
import script from "./ShowcaseList.vue?vue&type=script&lang=js&"
export * from "./ShowcaseList.vue?vue&type=script&lang=js&"
import style0 from "./ShowcaseList.vue?vue&type=style&index=0&id=2bf8492d&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf8492d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Showcase: require('/var/www/cicada-idnr-beta/nuxt/components/ShowcaseList/Showcase.vue').default})
