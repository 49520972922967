import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c7829106&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GovBanner: require('/var/www/cicada-idnr-beta/nuxt/components/GovBanner/GovBanner.vue').default,Search: require('/var/www/cicada-idnr-beta/nuxt/components/Search/Search.vue').default,NavBlock: require('/var/www/cicada-idnr-beta/nuxt/components/NavBlock/NavBlock.vue').default,PopularTopicsList: require('/var/www/cicada-idnr-beta/nuxt/components/PopularTopicsList/PopularTopicsList.vue').default,InfoBlock: require('/var/www/cicada-idnr-beta/nuxt/components/InfoBlock/InfoBlock.vue').default,SocialButton: require('/var/www/cicada-idnr-beta/nuxt/components/SocialButton/SocialButton.vue').default})
