//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    popular_topic: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "inactive",
    },
    contentField: {
      type: String,
      validator(value) {
        return ["content"].includes(value);
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    imgUrl() {
      return this.$props.popular_topic?.featuredImage?.node?.mediaItemUrl;
    },
    popularTopicTarget() {
      let target = "_self";
      if (
        this.popular_topic &&
        this.popular_topic.acf.url.toString().toLowerCase().startsWith("http")
      ) {
        target = "_blank";
      }
      return target;
    },
  },
};
