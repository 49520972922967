//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCarousel } from "bootstrap-vue";
import Search from "~/components/Search/Search";
import NavBlock from "~/components/NavBlock/NavBlock";
import SocialButton from "~/components/SocialButton/SocialButton.vue";
import InfoBlock from "~/components/InfoBlock/InfoBlock";
import GovBanner from "~/components/GovBanner/GovBanner.vue";
import GetMainMenu from "~/apollo/queries/getMainMenu";
import GetSecondaryMenu from "~/apollo/queries/getSecondaryMenu";
import InfoMenu from "~/apollo/queries/getInfoMenu";
import GetQuote from "~/apollo/queries/getQuote";
import PrimaryShowcase from "~/apollo/queries/primaryShowcase.gql";
import GetFooterText from "~/apollo/queries/getFooterText";
import ShowcaseList from "~/components/ShowcaseList/ShowcaseList.vue";
import PopularTopicsList from "~/components/PopularTopicsList/PopularTopicsList.vue";

// New GA integration
// function gtag() {
//   window.dataLayer.push(arguments);
// }
// if (process.client) {
//   window.dataLayer = window.dataLayer || [];
//   gtag("js", new Date());
//   gtag("config", "G-E9PYKW33SP");
//   gtag("send", "pageview");
// }

// Old school GA integration
function gtag() {
  window.dataLayer.push(arguments);
}

if (process.client) {
  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", "G-E9PYKW33SP", { debug_mode: true });
}

export default {
  components: {
    PopularTopicsList,
    // ShowcaseList,
    Search,
    NavBlock,
    SocialButton,
    InfoBlock,
    BCarousel,
    GovBanner,
  },
  data() {
    return {
      newMenu: [],
      showMenu: false,
      socialList: [
        {
          label: "Email",
          url: "mailto: cicada-idnr@gmail.com?subject=Message from CICADA",
        },
        {
          label: "Facebook",
          url: "https://www.facebook.com/IllinoisDNR/",
        },
        {
          label: "Twitter",
          url: "https://twitter.com/IllinoisDNR",
        },
        {
          label: "Instagram",
          url: "https://instagram.com/illinoisdnr?igshid=1kg6i3idj9cax",
        },
      ],
    };
  },
  head() {
    return {
      meta: [
        {
          hid: "description",
          name: "description",
          content: "The CICADA website is an archive of resources for enabling healthy habitats on private property. We have collected the best ideas for conservation inclusive residential and commercial development in one place.",
        },
        {
          property: "og:title",
          content: "CICADA—Conservation Inclusive Development",
        },
        {
          property: "og:description",
          content: "The CICADA website is an archive of resources for enabling healthy habitats on private property. We have collected the best ideas for conservation inclusive residential and commercial development in one place.",
        },
        {
          property: "og:image",
          content: "https://cicada-idnr.s3.amazonaws.com/wp-content/uploads/2024/11/04150103/cicada-site-image.jpg",
        },
        {
          property: "og:url",
          content: "https://cicada-idnr.org/",
        },
        {
          property: "og:type",
          content: "website",
        },
      ]
    };
  },
  computed: {
    updatedMenu() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.newMenu = [];
      for (let i = 0; i < this.secondaryMenu.length; i++) {
        this.newMenu.push(this.secondaryMenu[i]);
      }
      this.newMenu.push({
        databaseId: 0,
        label: "Donate to Conservation",
        menuItemCustom: {
          color: "autumn",
        },
        path: "https://ilconservation.org/Get-Involved/Donate",
      });
      return this.newMenu;
    },
    featureTarget() {
      let target = "_self";
      if (
        this.showcase &&
        this.showcase.acf.url.toString().toLowerCase().startsWith("http")
      ) {
        target = "_blank";
      }
      return target;
    },
    quote() {
      if (this.post) {
        return this.post.content;
      } else {
        return "";
      }
    },
  },
  apollo: {
    postBy: {
      query: GetFooterText,
      update(data) {
        return data.postBy;
      },
    },
    post: {
      query: GetQuote,
      update(data) {
        return data.post;
      },
    },
    mainMenu: {
      query: GetMainMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
    secondaryMenu: {
      query: GetSecondaryMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
    showcase: {
      query: PrimaryShowcase,
    },
    menu: {
      query: InfoMenu,
    },
  },
  watch: {
    showcase(_new) {
      console.log("Showcase: ", _new);
    },
  },
  methods: {
    showNav() {
      if (this.showMenu === false) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
  },
};
