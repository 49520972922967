//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    submitSearch(evt) {
      evt.preventDefault();
      const input = document.getElementById("searchq");
      if (input.value !== "") {
        const { value } = input;
        this.$router.push({
          path: "/search",
          query: {
            term: value,
          },
        });
      }
    },
  },
};
