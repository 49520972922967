import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e018851c = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _8056dc38 = () => interopDefault(import('../pages/certifications.vue' /* webpackChunkName: "pages/certifications" */))
const _6bee523a = () => interopDefault(import('../pages/page.vue' /* webpackChunkName: "pages/page" */))
const _78429d8a = () => interopDefault(import('../pages/resources.vue' /* webpackChunkName: "pages/resources" */))
const _1cf36fda = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a8ff8250 = () => interopDefault(import('../pages/showcase.vue' /* webpackChunkName: "pages/showcase" */))
const _227675f8 = () => interopDefault(import('../pages/specs1.vue' /* webpackChunkName: "pages/specs1" */))
const _225a46f6 = () => interopDefault(import('../pages/specs2.vue' /* webpackChunkName: "pages/specs2" */))
const _eeac4aca = () => interopDefault(import('../pages/showcases/_.vue' /* webpackChunkName: "pages/showcases/_" */))
const _324d1685 = () => interopDefault(import('../pages/popular_topics/_.vue' /* webpackChunkName: "pages/popular_topics/_" */))
const _a0a8cf92 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _dd766078 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _e018851c,
    name: "about"
  }, {
    path: "/certifications",
    component: _8056dc38,
    name: "certifications"
  }, {
    path: "/page",
    component: _6bee523a,
    name: "page"
  }, {
    path: "/resources",
    component: _78429d8a,
    name: "resources"
  }, {
    path: "/search",
    component: _1cf36fda,
    name: "search"
  }, {
    path: "/showcase",
    component: _a8ff8250,
    name: "showcase"
  }, {
    path: "/specs1",
    component: _227675f8,
    name: "specs1"
  }, {
    path: "/specs2",
    component: _225a46f6,
    name: "specs2"
  }, {
    path: "/showcases/*",
    component: _eeac4aca,
    name: "showcases-all"
  }, {
    path: "/popular_topics/*",
    component: _324d1685,
    name: "popular_topics-all"
  }, {
    path: "/",
    component: _a0a8cf92,
    name: "index"
  }, {
    path: "/*",
    component: _dd766078,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
